import { lazy } from "react";
const DownloadMobile = lazy(() => import("../components/navbar/downloadMobile/DownloadMobile"));
const Links = lazy(() => import("../components/navbar/links/Links"));
const Faq = lazy(() => import("../components/navbar/faq/Faq"));
const HelpDocuments = lazy(() => import("../components/navbar/helpDocuments/HelpDocuments"));
const FeedBack = lazy(() => import("../components/navbar/feedBack/FeedBack"));
const AdminFeedback = lazy(() => import("../components/admin/admin-feedback/AdminFeedback"));
const AdminRequest = lazy(() => import("../components/adminRequest/index"));
const ReportsPageComponent = lazy(() => import("../components/managereports/reportsNew"));
const AbsenceReport = lazy(()=> import("../components/admin/generate-absence-report/Generate Report"))
const RecentAbsenceReports = lazy(()=> import("../components/admin/recent-absence-report/Generate Recent Report"))
const ContactPage = lazy(()=> import("../components/admin/admin-contact-page/contactPage"));
const StationAccess = lazy(()=> import("../components/admin/station-access/StationAccess"));
const ManageDocuments = lazy(()=> import("../components/admin/manageDocument/ManageDocuments"));
const CreateRelease = lazy(()=> import("../components/createUpdateRelease/releaseCreateUpdate"));
const ManageAbsenceTypes = lazy(()=> import("../components/admin/manageAbsenceTypes/ManageAbsenceTypes"));
const AddEditAbsenceTypes=  lazy(()=> import("../components/admin/manageAbsenceTypes/AddEditAbsenceTypes"));

const AbsenceHistory = lazy(()=> import("../components/absenceHistory/AbsenceHistory"));
const HomePageComponent = lazy(() =>
  import("../components/home-page/homePageComponent")
);
const MyTimeDashBoard = lazy(() =>
  import("../components/home-page/home-page")
);
const AbsentRequestFormContextProvider = lazy(() =>
  import(
    "../components/absentRequestForm/absentFormContext/absentContextProvider"
  )
);
const GenerateMandoReport = lazy(() => import("../components/admin/generate-Mando-report/GenerateMandodReport"));
const MandoReportDetails = lazy(() => import("../components/admin/generate-Mando-report/GenerateMandodReport/mandoReportscreen"));
const MandoRequestConfirmationScreen = lazy(() => import("../components/admin/generate-Mando-report/GenerateMandodReport/MandoRequestConfirmationScreen"));
const MandoRequestWorkLocation = lazy(() => import("../components/admin/generate-Mando-report/GenerateMandodReport/MandoRequestWorkLocation"));
const MandoRequestScreen = lazy(() => import("../components/admin/generate-Mando-report/GenerateMandodReport/mandoRequestScreen"))
const MandoProfile = lazy(() => import("../components/navbar/mandoProfile/mandoProfileContextProvider"));
const MandoReportRecap = lazy(() => import("../components/admin/generate-Mando-report/GenerateMandodReport/mandoRequestRecap"));
const MandoAllRequests = lazy(() => import("../components/admin/generate-Mando-report/GenerateMandodReport/mandoAllReports"));
const MandoReleaseConfirmation = lazy(() => import("../components/admin/generate-Mando-report/GenerateMandodReport/MandoReleaseConfirmation"));
const MandoExtendConfirmation = lazy(() => import("../components/admin/generate-Mando-report/GenerateMandodReport/MandoExtendConfirmation"));
const MandoRequestSummaryPrint = lazy(() => import("../components/admin/generate-Mando-report/GenerateMandodReport/MandoRequestSummaryPrint"))
const UnionProfilePage = lazy(() => import("../components/admin/union-profile-page/unionProfile"))
const SetMyHomePage = lazy(() => import("../components/setHomePage/SetMyHomePage"));
const Notification = lazy(() => import("../components/notification/Notificationscreen"));

const routes = [
  {
    path: "/",
    exact: true,
    component: HomePageComponent,
    id: 1,
  },
  {
    path: "/request-form",
    exact: true,
    component: AbsentRequestFormContextProvider,
    id: 2,
  },
  {
    path: "/admin",
    exact: true,
    component: AdminRequest,
    id: 3,
  },
  {
    path: "/links",
    exact: true,
    component: Links,
    id: 4,
  },
  {
    path: "/faq",
    exact: true,
    component: Faq,
    id: 5,
  },
  {
    path: "/feedBack",
    exact: true,
    component: FeedBack,
    id: 6,
  },
  {
    path: "/download-mobile",
    exact: true,
    component: DownloadMobile,
    id: 7,
  },
  {
    path: "/absence-report",
    exact: true,
    component: AbsenceReport,
    id: 8,
  },
  {
    path: "/distro",
    exact: true,
    component: ReportsPageComponent,
    id: 9,
  },
  {
    path: "/recent",
    exact: true,
    component: RecentAbsenceReports,
    id: 9,
  },
  {
    path: "/admin-contacts",
    exact: true,
    component: ContactPage,
    id: 9,
  },
  {
    path: "/station-access",
    exact: true,
    component: StationAccess,
    id: 10,
  },{
    path: "/absence-history",
    exact: true,
    component: AbsenceHistory,
    id: 11,
  },
  {
    path:"/helpDocuments",
    exact: true,
    component: HelpDocuments,
    id: 12
  },
  {
    path:"/manage-documents",
    exact: true,
    component: ManageDocuments,
    id:13
  },
  {
    path:"/create-update-Release",
    exact: true,
    component: CreateRelease,
    id:14
  },
  {
    path:"/manage-absence-types",
    exact: true,
    component: ManageAbsenceTypes,
    id:15
  },
  {
    path:"/manage-absence-types/:mode",
    exact: true,
    component: AddEditAbsenceTypes,
    id:16
  },
  {
    path:"/mando_details",
    exact: true,
    component: GenerateMandoReport,
    id:17
  },
  {
    path:"/mando_ReportDetails",
    exact: true,
    component: MandoReportDetails,
    id:17
  },
  {
    path:"/mando_ReportConfirmation",
    exact: true,
    component: MandoRequestWorkLocation,
    id:18
  },
  {
    path:"/mando_RequestDetails",
    exact: true,
    component: MandoRequestScreen,
    id:19
  },
  {
    path: "/mando-profile",
    exact: true,
    component: MandoProfile,
    id: 20,
  },
  {
    path: "/mando-reportRecap",
    exact: true,
    component: MandoReportRecap,
    id: 20,
  },
  {
    path: "/mando_releaseConfirmation",
    exact: true,
    component: MandoReleaseConfirmation,
    id: 21,
  },
  {
   path: "/mando_extendConfirmation",
   exact: true,
   component: MandoExtendConfirmation,
   id: 22,
  },
  {
   path: "/mando_AllRequests",
   exact: true,
   component: MandoAllRequests,
   id: 23,
  },
  {
    path: "/set_HomePage",
    exact: true,
    component: SetMyHomePage,
    id: 24,
   },
   {
    path: "/notification",
    exact: true,
    component: Notification,
    id: 25,
   },
   {
    path: "/mandoSummary",
    exact: true,
    component: MandoRequestSummaryPrint,
    id: 26
   },
   {
    path: "/myTimeDashboard",
    exact: true,
    component: MyTimeDashBoard,
    id: 27,
  },
  {
    path: "/mandoConfirmation",
    exact: true,
    component: MandoRequestConfirmationScreen,
    id: 28,
  },
  {
    path: "/unionProfile",
    exact: true,
    component: UnionProfilePage,
    id: 29,
  },
  {
    path: "/adminFeedback",
    exact: true,
    component: AdminFeedback,
    id: 30,
  },
];

export default routes;