import React, {useEffect} from 'react';
import { BrowserRouter } from "react-router-dom";
import { Provider } from 'react-redux';
import "bootstrap/dist/css/bootstrap.min.css";
import { ThemeProvider, createTheme } from "@material-ui/core";
import ThemeConfig from "./config/theme";
import RouteComponent from './routes/RouteComponent';
import configureStore from './store/store';
import './app.scss';
import resc from "./utils/resource.json"
export const {store}  = configureStore();
window.dynatraceUserId = ""
function App() {
  var resource = resc[localStorage.getItem("envDetail")];
  useEffect(()=> {
    if (resource.DynatraceBeaconURL) {
      const script = document.createElement('script');
      script.src = resource.DynatraceBeaconURL;
      script.crossorigin = "anonymous";
      script.type = "text/javascript";

      document.head.appendChild(script);
    }
  }, []);

  const Theme = createTheme(ThemeConfig);
  return (
    <ThemeProvider theme={Theme}>
      <Provider store={store}>
        <div className="appStyles">
          <BrowserRouter>
              <RouteComponent/>
          </BrowserRouter>
        </div>
      </Provider>
    </ThemeProvider>
  );
}

export default App;
